@import "./common";
@import "./_signIn.scss";
@import "./_orderVerification.scss";

@media screen and (max-width: 550px) {
  .modal-content-padding {
    padding: 0 !important;
  }
  .ReactModal__Content--after-open,
  .Modal__Bootstrap,
  .modal-dialog {
    width: 90% !important;
  }
  .acct-modal-text {
    text-align: center;
  }
}
.acct-modal-heading {
  padding: 0px 10px 5px 10px;
  font-size: 20px;
  color: #9c9a9c;
}

.acct-modal-close-btn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.modal-content-padding {
  padding: 0 30px;
}

.modal-body-padding {
  padding: 0 20px;
}

.acct-modal-text {
  font-size: 16px;
  margin: 0 12px 20px 12px;
}

.checkbox-container .checkbox-fake-span {
  color: #34495e;
  padding: 0.5rem 0.25rem;
}

.checkbox-container input {
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 6px;
  outline: none;
  transition-duration: 0.3s;
  background-color: rgba(119, 121, 132, 0.1);
  cursor: pointer;
}

.nav-search-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.checkbox-container .checkbox-input:checked {
  background-color: #12b2ea;
}

.checkbox-container .checkbox-input-faded:checked {
  background-color: rgba(18, 178, 234, 0.24);
}

.checkbox-container input:checked + .checkbox-fake-span::before {
  font-family: FontAwesome;
  content: "\f00c";
  display: block;
  text-align: center;
  color: white;
  position: absolute;
  left: 4px;
  top: 2px;
  font-size: 11px;
  pointer-events: none;
}

.modal-header .close {
  background-color: black;
  border: 1px solid black;
  font-weight: bold;
  color: #ffffff;
}

.refer-sign-in-status-popup,
.refer-sign-up-to-get-reward-popup {
  .refer-email-success-button {
    width: 100%;
    height: 72px;
  }
}
