.failed-container,
.notify-container,
.verification-complete,
.enter-code-verify {
  .main-default-orange-button {
    width: 100%;
    height: 72px;

    @media (max-width: 400px) {
      height: 60px;
    }
  }
}
