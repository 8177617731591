.select-value-container {
  padding-left: 60px !important;
  position: relative;
  overflow: visible !important;
  // display: flex !important;
  @media (max-width: 875px) {
    padding-left: 46px !important;
  }
  @media (max-width: 410px) {
    padding-left: 35px !important;
  }
}

.react-select-3__control {
  border: 0 !important;
  border-color: transparent !important;
  border-radius: 5px !important;
  box-shadow: 0 20px 70px 0 rgba(31, 32, 33, 0.12) !important;
  background-color: #ffffff !important;
  height: 72px !important;
  font-size: 16px !important;

  @media (max-width: 483px) {
    font-size: 15px !important;
  }
  @media (max-width: 390px) {
    font-size: 14px !important;
  }
}

.react-select-3__indicators {
  display: none !important;
}

.react-select-3__placeholder {
  color: rgba(31, 32, 33, 0.65) !important;
  font-size: 16px !important;
}

.react-select-3__menu {
  border-radius: 0px 0px 25px 25px !important;
  box-shadow: 0 5px 20px 0 rgba(38, 38, 38, 0.05) !important;
  padding-top: 9px !important;
  margin-top: -4px !important;

  @media (max-width: 710px) {
    z-index: 4 !important;
  }
}

.react-select-3__menu-list {
  &::-webkit-scrollbar {
    width: 0px;
  }
}
.react-select-3__option {
  height: 60px !important;
  cursor: pointer !important;
  padding: 0 !important;
}

.react-select-3__control--is-focused {
  z-index: 5;
  border: 2px solid black !important;
}

.select-merchant-error {
  .react-select-3__control,
  .react-select-3__control--is-focused {
    border: 1px solid rgba(238, 80, 80, 0.48) !important;
  }
}

.react-select-3__single-value {
  right: 0 !important;
  left: 0 !important;
  padding: 2px 14px 2px 60px !important;
  overflow: visible !important;

  @media (max-width: 875px) {
    padding: 2px 14px 2px 46px !important;
  }
  @media (max-width: 410px) {
    padding: 2px 7px 2px 35px !important;
  }
}

.card-in-sell-cart {
  .react-select-3__control {
    background-color: #f7f7f8 !important;
    box-shadow: none !important;
  }
}

.react-select-3__option,
.react-select-3__option--is-selected,
.react-select-3__option--is-focused {
  background-color: inherit !important;
  color: #777984 !important;

  &:hover {
    color: #1f2021 !important;
  }
}

.setup-verification-method {
  .react-select-3__indicators {
    display: flex !important;
  }

  .react-select-3__indicator-separator {
    display: none !important;
  }

  .react-select-3__control {
    box-shadow: 0 20px 70px 0 rgba(34, 35, 36, 0.12) !important;
    background-color: #ffffff !important;
    padding-right: 10px;
    padding-left: 10px;
    cursor: pointer;
  }

  .react-select-3__control--is-focused {
    cursor: auto;
  }

  .react-select-3__option {
    padding-left: 23px !important;
    height: fit-content !important;
    margin-top: 0px;
    margin-bottom: 16px;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .react-select-3__menu {
    margin-top: 5px !important;
  }

  .react-select-3__menu-list {
    max-height: 200px;
  }

  .react-select-3__single-value {
    padding: 7px !important;
  }

  .custom-value-container-selected {
    margin-top: 15px !important;
  }
}
