.sign-in-pop-up-form-main {
  .small-margin-bottom {
    margin-bottom: 20px;
  }
  .regular-margin-bottom {
    margin-bottom: 26px;
  }

  .sign-in-input-div {
    @media (max-height: 975px) {
      margin-top: 20px;
    }
    @media (max-height: 900px) {
      height: 67px;
      margin-top: 21px;
    }
    @media (max-height: 790px) {
      height: 60px;
      margin-top: 17px;
    }
  }
}

.sign-in-form-main {
  .sign-in-input-div {
    @media (max-width: 485px) {
      height: 63px;
    }
  }
}

.signin-popup-main-container {
  .social-sign-in-div {
    @media (max-height: 975px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    @media (max-height: 900px) {
      margin-top: 27px;
      margin-bottom: 27px;
    }
    @media (max-height: 850px) {
      margin-top: 22px;
      margin-bottom: 21px;
    }
    @media (max-height: 790px) {
      margin-top: 18px;
      margin-bottom: 16px;
    }
  }

  .social-sign-in-button {
    @media (max-height: 975px) {
      width: 100%;
    }
    @media (max-height: 790px) {
      height: 60px;
    }
  }
}
