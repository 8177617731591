.option-small-mobile {
  @media (min-width: 451px) {
    display: none !important;
  }
}

.nav-bar-extra-header {
  .nav-bar-extra-header-message a {
    color: #12b2ea;
  }
}

.nav-container-scrolled {
  max-height: 75px;
}

.mobile-menu {
  display: none;
  @media (max-width: 999px) {
    display: initial;
  }
}
