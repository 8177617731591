.footer-main-container {
  .social-icon-container {
    @media (max-width: 1040px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-gap: 8px;
    }
  }

  .social-icon-image {
    @media (max-width: 1040px) {
      width: 100%;
    }
  }
}

.standard-placeholder {
  ::placeholder {
    color: rgba(31, 32, 33, 0.5);
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(31, 32, 33, 0.5);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(31, 32, 33, 0.5);
  }
}
