//not sure if everything needs it i removed it from a random file and placed it here
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  margin: 0px;
  padding: 0px;
  font-family: Montserrat, DejaVu Sans, Verdana, sans-serif !important;
  font-size: 14px !important;
}

input,
textarea {
  margin: 0px;
  padding: 0px;
  font-family: Montserrat, DejaVu Sans, Verdana, sans-serif !important;
  font-size: 16px !important;
}

h1 {
  font-weight: 400;
  font-size: 28px;
  line-height: 1.2;
  padding-bottom: 4px;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 1em;
  @media (max-width: 992px) {
    font-size: 16px;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}

ol {
  margin: 0 0 0;
  padding: 0;
  counter-reset: item;
}

ol > li {
  margin: 0;
  padding: 0 0 0 2em;
  text-indent: -2em;
  list-style-type: none;
  counter-increment: item;
  margin-bottom: 10px;
}

ol > li:before {
  display: inline-block;
  width: 1.5em;
  padding-right: 0.5em;
  font-weight: bold;
  text-align: right;
  content: counter(item) ".";
}

.mar-t-10 {
  margin-top: 10px;
}

.mar-t-125 {
  margin-top: 125px !important;
  @media (max-width: 767px) {
    margin-top: 110px !important;
  }
}

.mar-t-128 {
  margin-top: 128px !important;
  @media (max-width: 767px) {
    margin-top: 85px !important;
  }
}

.mar-t-190 {
  margin-top: 190px !important;
  @media (max-width: 767px) {
    margin-top: 150px !important;
  }
}

.mar-t-128-108 {
  margin-top: 128px !important;
  @media (max-width: 767px) {
    margin-top: 108px !important;
  }
}

.header-t-175-sm-150 {
  margin-top: 175px !important;
  @media (max-width: 767px) {
    margin-top: 150px !important;
  }
}

.mar-b-10 {
  margin-bottom: 10px;
}

.hidden {
  visibility: hidden;
}

.partner-specific-color {
  color: #00aeef;
}

// end demo-specific

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.lnk {
  color: #209ebe;
  cursor: pointer;
}

.hide {
  display: none !important;
}

.static-content-container {
  background-color: white;
  border-radius: 5px;
  font-size: 16px;
}

.content {
  text-align: center;
  position: relative;
  width: 100%;
  margin-bottom: 2 0px;
}

.copy {
  padding: 10px;
  border-radius: 5px;

  @media (max-width: 544px) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.pointer {
  cursor: pointer;
}

.margin-none {
  margin: 0;
}

//width of container based on nav and footer width
.main-container-page-width {
  width: 70%;
  margin: 0 auto;
  @media (max-width: 1700px) {
    width: 75%;
  }
  @media (max-width: 1600px) {
    width: 80%;
  }
  @media (max-width: 1250px) {
    width: 85%;
  }
  @media (max-width: 1150px) {
    width: 95%;
  }
  @media (max-width: 924px) {
    width: 85%;
  }
  @media (max-width: 728px) {
    width: 90%;
  }
}

.button-transition-hover-bgColor-boxShadow {
  transition: background-color 0.2s, box-shadow 0.5s;
}

.button-transition-hover-bgColor {
  transition: background-color 0.35s;
}

.button-transition-hover-bgColor-color {
  transition: background-color 0.35s, color 0.35s;
}

.button-transition-hover-boxShadow {
  transition: box-shadow 0.2s;
}

.transition-left {
  transition: left ease-out 0.5s;
}

.transition-left-faster {
  transition: left linear 0.3s;
}

.orange-caret {
  caret-color: #fda536;
}

.clear-input-style {
  border: 0;
  outline: none;
  &:hover,
  &:focus,
  &:disabled,
  &:active,
  &:visited {
    border: 0;
    outline: none;
  }
}

.spinner-rotate {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.remove-number-input-arrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  /* Firefox */
  input[type="number"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    -moz-appearance: textfield;
  }
}

.forgot-password-main {
  #recaptchaTargetPassReset {
    @media (max-width: 335px) {
      transform: scale(0.77);
      -webkit-transform: scale(0.77);
      transform-origin: 0 0;
      -webkit-transform-origin: 0 0;
    }
  }
}

.display-grid {
  display: grid;
}

#cardcash-root,
#cardcash-root iframe,
iframe[name="paypal"],
iframe[name="bitpay"] {
  z-index: 10000 !important;
}

.mobile-checkout-button-container {
  .item-line {
    @media (max-height: 700px) {
      margin-bottom: 10px;
    }
    @media (max-height: 650px) {
      margin-bottom: 5px;
    }
  }
  .checkbox-container {
    @media (max-height: 700px) {
      margin-bottom: 10px;
    }
    @media (max-height: 650px) {
      margin-bottom: 6px;
    }
  }

  .checkout-page-checkout-button,
  .cart-page-checkout-button {
    @media (max-height: 600px) {
      height: 50px;
    }
  }
}

.card-knox-main {
  input {
    font-size: 16px !important;
  }
}

.nav-links-link,
.nav-links-link:hover,
.nav-links-link:visited,
.nav-links-link:active,
.nav-links-link:focus,
.nav-links-link:focus-visible,
.nav-links-link:focus-within {
  align-self: center;
  height: fit-content;
  color: #72737c;
  font-size: 15px;
  text-decoration: none !important;
}

.nav-links-link:hover {
  color: #4e4f54;
}
